// icons
@import "../fonts/materialdesignicons.css";
@import "../fonts/fontawesome.css";

// core
@import "./core-dark.css";
@import "./theme-default-dark.css";
@import "./demo.css";
@import "./libs/animate-css/animate.css";

// Vendors CSS
@import "./libs/perfect-scrollbar/perfect-scrollbar.css";
@import "./libs/typeahead-js/typeahead.css";
@import "./libs/datatables-bs5/datatables.bootstrap5.css";
@import "./libs/datatables-responsive-bs5/responsive.bootstrap5.css";
@import "./libs/apex-charts/apex-charts.css";
@import "./libs/swiper/swiper.css";

// menu

.menu-vertical .menu-item .menu-link > div:not(.badge){
  overflow: initial; 
}
.layout-menu-fixed:not(.layout-menu-collapsed) .layout-page, .layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page{
  padding-left: 18.25rem;
}

//nav

.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page{
  padding-top: 75px!important;
}
.layout-navbar.container-xxl.navbar.navbar-expand-xl.navbar-detached.align-items-center.bg-navbar-theme{
  background-color: rgba(48, 51, 78, 0.26)!important;
  box-shadow: 0px 4px 8px -4px rgba(20, 21, 33, 0.42);
  padding: 0 1.25rem;
}
.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, .layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, .layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar{
  left: 19.75rem;
  margin-top: 10px;
}
.layout-navbar-fixed .layout-wrapper:not(.layout-without-menu) .layout-page.layout-page-pt {
  padding-top: 3px!important;
}
//login

.authentication-wrapper.authentication-basic .authentication-inner{
  max-width: 550px!important;
}
.authentication-wrapper.authentication-basic.container-p-y {
  background-image: url(../images/360_F_231544825_ElnBQbd6IgbIvP8p9xmZ3YkZhfvfWwna.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
.bg-style {
  background-color: #120f14eb;
  width: 100%;
}
input:autofill,
input:-webkit-autofill-strong-password,
input:-webkit-autofill-strong-password-viewable,
input:-webkit-autofill-and-obscured {
  -webkit-text-fill-color: #ffffff !important;
  transition: background-color 5000s ease-in-out 0s !important;
  background-color: #333 !important;
  box-shadow: 0 0 0 #fff !important;
  color: #fff !important;
}

//font/text

.text-info{
  color: rgba(6, 100, 245, 1) !important;
}
.fs-30{
  font-size: 30px !important;
}

//btn 

.btn-outline-info{
  color: rgba(6, 100, 245, 1) !important;
  border-color: rgba(6, 100, 245, 1) !important;
}
.btn-outline-info:hover{
  color: rgba(6, 100, 245, 1) !important;
  background-color: rgba(26, 67, 117, 0.81) !important;
  border-color: rgba(6, 100, 245, 1) !important;
}
.bg-label-info{
  color: #4884ed !important;
  background-color: rgba(16, 78, 154, 0.68) !important;
}
.input-group:has(button) .form-control{
  padding: 0.7813rem 0.7813rem !important;
}

//modal

.modal.show {
  display: block !important;
  background-color: rgba(0, 0, 0, 0.74);
  transition: 0.2s ease;
}
.btn-close-x{
  font-size: 25px;
  background: none;
  border: none;
  line-height: normal;
  color: #666cff !important;
}
.modal-content{
  background-color: rgba(48, 51, 78, 0.93);
}
.modal .form-control {
  background-color: rgba(48, 51, 78, 0.078);
}

//dataTable

.rdt_TableHead{
  background-color: #282a42!important;
}
.rdt_TableHeadRow{
  background-color: #282a42!important;
  border-bottom: 0px!important;
}
.rdt_Pagination{
  background-color: #282a42!important;
  border-top: 0px!important;
  border-radius:  0 0 10px 10px;
}
.rdt_TableBody{
  background-color: #282a42!important;
}
.MuiCheckbox-root{
  color: rgba(6, 100, 245, 1)!important;
}
.rdt_TableRow{
  background-color: rgba(39, 41, 55, 0)!important;
  color: #fff !important;
}
.dataTables_wrapper{
  border-radius: 10px 10px 0 0;
}
.data-table-extensions > .data-table-extensions-filter > .filter-text{
  color: rgba(6, 100, 245, 1)!important;
  border-bottom: 0px!important;
}
.data-table-extensions > .data-table-extensions-action > button.drop, .data-table-extensions > .data-table-extensions-action > button:hover{
  background-color: #282a42!important;
}
.data-table-extensions > .data-table-extensions-action > .dropdown{
  background-color: #282a42!important;
  border: 1px solid rgba(6, 100, 245, 1)!important;
}
.data-table-extensions > .data-table-extensions-action > .dropdown button:hover{
  background-color: #333657!important;
}

//form

.input-active{
  border: 2px solid rgba(6, 100, 245, 1);
}
.input-active.form-control:focus, .input-active.form-select:focus {
  border-color: rgb(6, 70, 165);
}
.alert-info{
  color: #4884ed !important;
}
.form-control-success{
 background-color: #384849!important;
 border: none;
 color: #67cb24!important;
}
.input-warning {
  border: 1px solid #fdb528!important;
}
.custom-option-icon .form-check-input.input-withdraw{
  margin-top: 10px!important;
}

//notification

.notificationCard {
  background-color: rgba(19, 20, 41, 0.88);
  border: 2px solid;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
}
.notificationMessg {
  text-transform: none;
  font-size: 15px;
}
.notificationTitle {
  font-size: 14px;
  color: rgb(232, 230, 230);
}
.notificationCard.danger {
  border-color: #98404c;
  background-color: #4f374d;
  .notificationMessg {
    color: #ff4d49;
  }
}
.notificationCard.success {
  border-color: #518a3b;
  background-color: #405d45;
  .notificationMessg {
    color: #72e128;
  }
}
.notificationCard.warning {
  border-color: #97743b;
  background-color: #4f4748;
  .notificationMessg {
    color: #fdb528;
  }
}

//img

.avatar {
  width: 3.5rem!important;
  height: 3.5rem!important;
}
.avatar.avatar-xs {
  width: 1.563rem!important;
  height: 1.563rem!important;
}
.avatar-icon {
  width: 3.25rem !important;
  height: 3.25rem !important;
}

//stats

.nav-tabs.nav-tabs-widget .nav-link{
  height: 160px !important;
  width: 180px!important;
}
.nav-tabs.nav-tabs-widget .nav-link.page-white{
  height: 100px !important;
  width: 120px!important;
}
.nav-item .nav-link .btn-icon{
  height: 110px!important;
  width: 110px!important;
}
.nav-item .nav-link .btn-icon.page-white-icon{
  height: 60px!important;
  width: 60px!important;
}
.nav-menu-mobil-media{
  display: none!important;
}
.icon-menu-mobil-media{
  display: none!important;
}

//media

@media (max-width: 900px) {
  .layout-menu-fixed:not(.layout-menu-collapsed) .layout-page, .layout-menu-fixed-offcanvas:not(.layout-menu-collapsed) .layout-page {
    padding-left: 0;
  }
  .layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, .layout-menu-fixed.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar, .layout-menu-fixed-offcanvas.layout-navbar-fixed:not(.layout-menu-collapsed) .layout-content-navbar:not(.layout-without-menu) .layout-navbar{
    left: 0!important;
  }
  .media-w-img{
    width: 30px;
  }
  .media-w-img-error{
    width: 100px;
  }
  .nav-tabs.nav-tabs-widget .nav-link{
    height: 75px !important;
    width: 75px!important;
  }
  .nav-item .nav-link .btn-icon{
    height: 70px!important;
    width: 70px!important;
  }
  .media-btn-sm{
    line-height: 1.2;
    font-size: 11px;
  }
  .media-ps-none{
    padding-left: 0px!important;
  }
  .media-ms-none{
    margin-left: 0px!important;
  }
  .media-p-none{
    padding: 0px!important;
  }
  .media-m-none{
    margin: 0px!important;
  }
  .media-p-2{
    padding: 0.5rem !important;
  }
  .media-p-3{
    padding: 1rem !important;
  }
  .media-px-4{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .media-px-1{
    padding-left: 0.3rem !important;
    padding-right: 0.3rem !important;
  }
  .media-py-2{
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .media-pe-none{
    padding-right: 0px!important;
  } 
  .media-me-none{
    margin-right: 0px!important;
  }
  .media-w-100{
    width: 100% !important;
  }
  .media-ms-3{
    margin-left: 1.25rem !important;
  }
  .media-fs-11{
    font-size: 11px!important;
  }
  .media-fs-12{
    font-size: 12px!important;
  }
  .media-fs-17{
    font-size: 17px!important;
  }
  .media-my-auto{
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .media-d-none{
    display: none !important;
  }
  .media-mb-0{
    margin-bottom: 0px!important;
  }
  .media-mt-2{
    margin-top: 0.5rem !important;
  }
  .media-my-2{
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .media-mt-5{
    margin-top: 3.5rem !important;
  }
  .media-m-5{
    margin: 3.5rem !important;
  }
  .media-mb-4{
    margin-bottom: 1.25rem!important;
  }
  .authentication-wrapper.authentication-basic .authentication-inner{
    max-width: 300px!important;
  }
  .media-w-img-logo{
    width: 130px!important;
  }
  .authentication-wrapper.authentication-basic.container-p-y{
    background-image: none!important;
  }
  .menu-mobil {
    transform: translate3d(0, 0, 0) !important;
    // transition: width 0.3s;
    z-index: 1100;
    position: fixed !important;
    top: 0 !important;
    height: 100% !important;
    left: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    transform: translate3d(-100%, 0, 0);
    will-change: transform, -webkit-transform;
  }
  .pt-75{
    padding-top: 75px !important;
  }
  .px-10{
    padding: 10px 10px!important;
  }
  .nav-menu-mobil-media{
    display: block!important;
  }
  .icon-menu-mobil-media{
    display: inline!important;
  }
  .nav-tabs.nav-tabs-widget .nav-link.page-white{
    width: 75px!important;
  }
  .media-text-start{
    text-align: left !important;
  }
  .media-text-center{
    text-align: center !important;
  }
  .layout-menu.menu-vertical{
    overflow-y: scroll;
  }
}

.custom-option-icon.is-invalid {
  border-color: #ff0000;
}
