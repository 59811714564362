.twitter-typeahead {
  display: block !important;
}
.twitter-typeahead .tt-menu {
  float: left;
  position: absolute;
  left: 0;
  top: 100%;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  display: none;
}
[dir=rtl] .twitter-typeahead .tt-menu {
  float: right;
  left: auto !important;
  right: 0 !important;
  text-align: right;
}
.twitter-typeahead .tt-menu .tt-suggestion {
  text-align: inherit;
  border: 0;
  width: 100%;
  display: block;
  white-space: nowrap;
  background: none;
  clear: both;
  cursor: pointer;
}
.twitter-typeahead .tt-menu .tt-suggestion p {
  margin: 0;
}
.twitter-typeahead .tt-hint {
  color: #999;
}
[dir=rtl] .twitter-typeahead .tt-input {
  direction: rtl;
}

.light-style .twitter-typeahead .tt-menu {
  min-width: 10rem;
  padding: 0.3125rem 0;
  font-size: 0.9375rem;
  z-index: 1000;
  margin: 0.125rem 0;
  color: #828393;
  box-shadow: 0px 5px 5px -3px rgba(76, 78, 100, 0.2), 0px 8px 10px 1px rgba(76, 78, 100, 0.14), 0px 3px 14px 2px rgba(76, 78, 100, 0.12);
  background-color: #fff;
  border-radius: 0.5rem;
}
.light-style .twitter-typeahead .tt-hint {
  color: #bbbcc4;
}
.light-style .twitter-typeahead .tt-suggestion {
  font-weight: 400;
  color: #828393;
  padding: 0.483rem 1rem;
}
.light-style .twitter-typeahead .tt-suggestion:hover, .light-style .twitter-typeahead .tt-suggestion:focus {
  text-decoration: none;
  color: #828393;
  background-color: rgba(76, 78, 100, 0.06);
}

.dark-style .twitter-typeahead .tt-menu {
  color: #a0a1b8;
  min-width: 10rem;
  padding: 0.3125rem 0;
  margin: 0.125rem 0;
  box-shadow: 0px 5px 5px -3px rgba(20, 21, 33, 0.2), 0px 8px 10px 1px rgba(20, 21, 33, 0.14), 0px 3px 14px 2px rgba(20, 21, 33, 0.12);
  font-size: 0.9375rem;
  background-color: #30334e;
  z-index: 1000;
  border-radius: 0.5rem;
}
.dark-style .twitter-typeahead .tt-menu .tt-suggestion {
  font-weight: 400;
  color: #a0a1b8;
  padding: 0.483rem 1rem;
}
.dark-style .twitter-typeahead .tt-menu .tt-suggestion:hover, .dark-style .twitter-typeahead .tt-menu .tt-suggestion:focus {
  text-decoration: none;
  color: #a0a1b8;
  background-color: rgba(234, 234, 255, 0.06);
}
.dark-style .twitter-typeahead .tt-hint {
  color: #777991;
}
